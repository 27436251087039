import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import TenantSelector from '@cimpress-technology/react-platform-tenant-selector';
import capitalize from 'lodash.capitalize';

export default class ProfilePop extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, viewportWidth: 0, selectedTenant: null, showTenantSelector: false };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ viewportWidth: window.outerWidth });
  }

  handleClose() {
    this.state.open && this.setState({ open: false });
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  onCurrentTenantChange = tenant => {
    this.setState({ selectedTenant: tenant, showTenantSelector: false });
    this.props.tenantSelectorProps.onTenantSelected(tenant);
  };

  onShowTenantSelector = () => {
    if (this.props.tenantSelectorProps && this.props.tenantSelectorProps.onOpen) {
      this.props.tenantSelectorProps.onOpen();
    }
    this.setState({ showTenantSelector: true, open: false });
  };

  onHideTenantSelector = () => {
    if (this.props.tenantSelectorProps && this.props.tenantSelectorProps.onClose) {
      this.props.tenantSelectorProps.onClose();
    }
    this.setState({ showTenantSelector: false });
  };

  render() {
    let {
      isLoggedIn,
      profile,
      onLogInClicked,
      onLogOutClicked,
      tenantSelectorProps,
      authToken,
      environment,
    } = this.props;
    let { email, name, picture, domain } = profile || {};
    const { selectedTenant, showTenantSelector } = this.state;
    name = name || 'Unknown';

    if (!domain) {
      domain = email ? capitalize(email.replace(/.*@/, '').split('.')[0]) : '';
    }

    if (!picture) {
      picture = `https://i2.wp.com/cdn.auth0.com/avatars/${name[0]}.png?ssl=1`;
    }

    if (!isLoggedIn) {
      return (
        <button onClick={onLogInClicked} className="btn btn-outline-secondary btn-sm">
          Log In
        </button>
      );
    }

    const { label: tenantLabel, value: tenantValue } = selectedTenant || {};
    const actingAs = tenantLabel || tenantValue || 'Unknown';

    let profileContents = (
      <div className="text-center">
        <div className="popover-content">
          <img className="avatar" src={picture} />
          <div style={{ marginBottom: '5px' }} className="username">
            {name}
          </div>
          <div style={{ marginBottom: '2px' }} className="email">
            {email}
          </div>
          {tenantSelectorProps ? (
            <div>
              {selectedTenant ? (
                <div>
                  Acting as:{' '}
                  <span className="clickable btn-link" onClick={this.onShowTenantSelector}>
                    {actingAs}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="popover-content">
          <button onClick={onLogOutClicked} className="btn btn-secondary btn-block">
            Log Out
          </button>
        </div>
      </div>
    );
    return (
      <div className="mcp-navbar-userbox">
        <Tooltip
          variety="popover"
          className="user-popover"
          show={this.state.open}
          onClickOutside={this.handleClose}
          direction="bottom"
          constraints={[
            {
              to: 'scrollParent',
              attachment: 'together',
              pin: ['right', 'top'],
            },
          ]}
          contents={profileContents}>
          <Fragment>
            {tenantSelectorProps && isLoggedIn ? (
              <TenantSelector
                authToken={authToken}
                show={showTenantSelector}
                onCurrentTenantChange={this.onCurrentTenantChange}
                resourceType={tenantSelectorProps.tenantType}
                environment={environment}
                onClose={this.onHideTenantSelector}
                {...tenantSelectorProps}
              />
            ) : null}
            <a className="clickable" onClick={this.toggle}>
              {this.state.viewportWidth > 500 ? (
                <div>
                  <div className="username">{name}</div>
                  {tenantSelectorProps && selectedTenant ? (
                    <div className="organization">Acting as: {actingAs}</div>
                  ) : (
                    <div className="organization">{domain}</div>
                  )}
                </div>
              ) : null}
              <img className="avatar" src={picture} />
            </a>
          </Fragment>
        </Tooltip>
      </div>
    );
  }
}

ProfilePop.propTypes = {
  profile: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    domain: PropTypes.string,
    sub: PropTypes.string,
  }),
  onLogOutClicked: PropTypes.func.isRequired,
  onLogInClicked: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
  tenantSelectorProps: PropTypes.shape({
    tenantType: PropTypes.string,
    onTenantSelected: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    show: PropTypes.bool,
  }),
};
