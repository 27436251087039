import { WebAuth } from "auth0-js";

// if promise hasn't been polyfilled polyfill it just for this file
var _Promise = typeof Promise === "undefined" ? require("es6-promise").Promise : Promise;

// https://auth0.com/docs/api-auth/tutorials/authorization-code-grant
export default class AuthorizationCodeGrant {
  constructor({
    clientID,
    redirectRoute = "",
    domain = "cimpress.auth0.com",
    audience = "https://api.cimpress.io/",
    scope = "offline_access"
  } = {}) {
    this.redirectUri = window.location.origin + redirectRoute;
    this.auth0 = new WebAuth({
      clientID,
      redirectUri: this.redirectUri,
      domain,
      audience,
      scope,
      responseType: "code"
    });
  }

  login({
    nextUri = window.location.href
  } = {}) {
    const state = btoa(nextUri);

    this.auth0.authorize({ state });
    return _Promise.resolve();
  }

  handleAuthentication({ 
    performRedirect = true 
  } = {}) {
    const authorizationCode = localStorage.getItem("authorizationCode");
    const nextUri = localStorage.getItem("nextUri");

    if (authorizationCode && nextUri) {
      localStorage.removeItem("authorizationCode");
      localStorage.removeItem("nextUri");

      return _Promise.resolve({ authorizationCode, redirectUri: this.redirectUri, nextUri });
    } 
    
    else if (this.wasAuth0Redirect()) {
      return this.handleRedirect({ performRedirect });
    }

    return _Promise.resolve({});
  }

  wasAuth0Redirect() {
    const parsedUrl = new URL(window.location.href);
    return parsedUrl.searchParams.get("code") && parsedUrl.searchParams.get("state");
  }

  handleRedirect({ performRedirect }) {
    const parsedUrl = new URL(window.location.href);
    const authorizationCode = parsedUrl.searchParams.get("code");
    const state = parsedUrl.searchParams.get("state");
    const nextUri = atob(state);

    if (performRedirect) {
      localStorage.setItem("authorizationCode", authorizationCode);
      localStorage.setItem("nextUri", nextUri);
      window.location = nextUri || "/";

      return _Promise.resolve({});
    } 

    return _Promise.resolve({ authorizationCode, redirectUri: this.redirectUri, nextUri });
  }
}
