import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '@cimpress/react-components/lib/Modal';
import find from 'lodash.find';
import SelectorModal from './SelectorModal';
import { getFulfillers } from '../../services/fulfillerIdentity';
import { getMerchants } from '../../services/merchantConfig';
import { getSavedTenants, putSavedTenants } from '../../services/customizr';

const noop = () => {};

/* eslint-disable jsx-a11y/no-static-element-interactions */
const HeaderLink = ({ selectedTenant, onClick }) => (
  <span>
    {selectedTenant && 'Acting as: '}
    <a className="clickable" onClick={onClick}>
      {selectedTenant || 'Select profile'}
    </a>
  </span>
);
/* eslint-enable jsx-a11y/no-static-element-interactions */

HeaderLink.propTypes = {
  selectedTenant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

HeaderLink.defaultProps = {
  selectedTenant: undefined,
  onClick: undefined,
};

export class TenantSelector extends Component {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    onCurrentTenantChange: PropTypes.func.isRequired,
    resourceType: PropTypes.string,
    forceSelection: PropTypes.bool,
    show: PropTypes.bool,
    environment: PropTypes.string,
    showLink: PropTypes.bool,
    onClose: PropTypes.func,
    modalTitle: PropTypes.string,
    modalHelpText: PropTypes.string,
    onReady: PropTypes.func,
  };

  static defaultProps = {
    environment: 'prd',
    resourceType: '',
    forceSelection: false,
    showLink: false,
    show: false,
    onClose: noop,
    modalTitle: 'Choose who to view',
    modalHelpText: '',
    onReady: noop,
  };

  static allowedFulfillers;
  static allowedMerchants;
  static savedTenants;

  constructor(props) {
    super(props);
    this.state = {
      selectedTenant: null, // backwards-compatibility with Customizr
      selectedMerchant: null,
      selectedFulfiller: null,
      allowedMerchants: [],
      allowedFulfillers: [],
      showModal: false,
    };
  }

  componentDidMount() {
    this.loadTenants();
    if (this.props.resourceType) {
      this.setTenantTypeStateFromProps();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.resourceType !== prevProps.resourceType) {
      this.setTenantTypeStateFromProps();
    }
  }

  setTenantTypeStateFromProps() {
    this.setState({ tenantType: this.props.resourceType });
  }

  createSelectedTenantFromMerchant() {}

  loadTenants = () => {
    this.setState({ fetchingFulfillers: true, fetchingMerchants: true, fetchingSettings: true });
    const { resourceType } = this.props;
    // load data if it doesn't exist on the static members
    Promise.all([
      !TenantSelector.allowedFulfillers
        ? getFulfillers(this.props.authToken, this.props.environment)
        : Promise.resolve(TenantSelector.allowedFulfillers),
      !TenantSelector.allowedMerchants
        ? getMerchants(this.props.authToken, this.props.environment)
        : Promise.resolve(TenantSelector.allowedMerchants),
      !TenantSelector.savedTenants
        ? getSavedTenants(this.props.authToken, this.props.environment)
        : Promise.resolve(TenantSelector.savedTenants),
    ])
      .then(([fulfillers, merchants, savedTenants]) => {
        TenantSelector.allowedMerchants = merchants || TenantSelector.allowedMerchants;
        TenantSelector.allowedFulfillers = fulfillers || TenantSelector.allowedFulfillers;
        TenantSelector.savedTenants = savedTenants || {};

        const allowedMerchants = TenantSelector.allowedMerchants || [];
        const allowedFulfillers = TenantSelector.allowedFulfillers || [];
        let { selectedTenant } = this.state;
        let restoredTenant;

        if (savedTenants.selectedTenant && (!resourceType || savedTenants.selectedTenant.tenantType === resourceType)) {
          switch (savedTenants.selectedTenant.resourceType) {
            case 'merchant':
              restoredTenant = {
                tenant: allowedMerchants.find(m => m.merchantId === savedTenants.selectedTenant.value),
                type: 'merchant',
              };
              break;

            case 'fulfiller':
              restoredTenant = {
                tenant: allowedFulfillers.find(m => m.internalFulfillerId === savedTenants.selectedTenant.value),
                type: 'fulfiller',
              };
              break;
          }
        } else if (resourceType === 'merchant' && savedTenants.recentMerchant) {
          restoredTenant = {
            tenant: allowedMerchants.find(m => m.merchantId === savedTenants.recentMerchant.value),
            type: 'merchant',
          };
        } else if (resourceType === 'fulfiller' && savedTenants.recentFulfiller) {
          restoredTenant = {
            tenant: allowedFulfillers.find(m => m.internalFulfillerId === savedTenants.recentFulfiller.value),
            type: 'fulfiller',
          };
        }

        if (restoredTenant && restoredTenant.tenant) {
          const details = this.onChange(restoredTenant);
          selectedTenant = {
            value: details.tenantId,
            label: details.tenantName,
            resourceType: details.tenantType,
          };
        }

        const selectedMerchant = savedTenants.recentMerchant && savedTenants.recentMerchant.value;
        const selectedFulfiller = savedTenants.recentFulfiller && savedTenants.recentFulfiller.value;

        //if there is a selected tenant notify the application
        if (selectedTenant) {
          this.props.onCurrentTenantChange(selectedTenant);
        }

        //notify the application that the selector is ready
        this.props.onReady();

        this.setState({
          fetchingFulfillers: false,
          fetchingMerchants: false,
          fetchingSettings: false,
          allowedMerchants,
          allowedFulfillers,
          selectedTenant,
          selectedMerchant,
          selectedFulfiller,
        });
      })
      .catch(error => console.error(error));
  };

  onChange = tenant => {
    this.setState({ tenantType: tenant.type });
    const tenantDetails = {
      tenantType: tenant.type,
    };

    const selection = tenant.tenant || {};

    if (tenant.type === 'merchant') {
      tenantDetails.tenantId = selection.merchantId;
      tenantDetails.tenantName = selection.displayName;
      this.setState({ selectedMerchant: selection.merchantId });
    } else {
      tenantDetails.tenantId = selection.internalFulfillerId;
      tenantDetails.tenantName = selection.name;
      this.setState({ selectedFulfiller: selection.internalFulfillerId });
    }

    this.setState({ ...tenantDetails });
    return tenantDetails;
  };

  showModal = () => {
    const { value, label, resourceType } = this.state.selectedTenant || {};
    this.setState({
      showModal: true,
      tenantId: value,
      tenantName: label,
      tenantType: resourceType || this.props.resourceType,
    });
  };

  closeModal = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      showModal: false,
    });
  };

  save = () => {
    const { tenantId, tenantName, tenantType } = this.state;
    const tenant = {
      value: tenantId,
      label: tenantName,
      resourceType: tenantType,
    };
    this.setState({ selectedTenant: tenant });
    const savedSetting = { selectedTenant: tenant };
    if (tenantType === 'merchant') {
      savedSetting.recentMerchant = tenant;
    }
    if (tenantType === 'fulfiller') {
      savedSetting.recentFulfiller = tenant;
      const fulfiller = find(this.state.allowedFulfillers, tenant => tenant.internalFulfillerId === tenantId);
      tenant.altValue = fulfiller ? fulfiller.fulfillerId : null;
    }
    TenantSelector.savedTenants = { ...TenantSelector.savedTenants, ...savedSetting };
    putSavedTenants(this.props.authToken, this.props.environment, TenantSelector.savedTenants);

    this.props.onCurrentTenantChange(tenant);
    this.closeModal();
  };

  render() {
    const {
      selectedTenant,
      selectedMerchant,
      selectedFulfiller,
      showModal,
      tenantType,
      tenantName,
      tenantId,
      allowedMerchants,
      allowedFulfillers,
      fetchingFulfillers,
      fetchingMerchants,
    } = this.state;
    const { label } = selectedTenant || {};
    const { authToken, resourceType, forceSelection, showLink, show, modalTitle, modalHelpText } = this.props;
    const mustSelectTenant = !selectedTenant && forceSelection;

    if (!fetchingFulfillers && !fetchingMerchants && allowedMerchants.length + allowedFulfillers.length === 0) {
      return (
        <Modal
          className="modal-overflow"
          show={showModal || show || mustSelectTenant}
          title={'No permissions'}
          onRequestHide={this.closeModal}
          closeButton={!mustSelectTenant}
          bsStyle={'danger'}
          footer={
            <div className="pull-right">
              <button className="btn btn-default" disabled={mustSelectTenant} onClick={this.closeModal}>
                Close
              </button>
            </div>
          }>
          You do not have permission to access this application. Please contact your administrator to provide you with
          access. If you don’t know who your administrator is, contact{' '}
          <a
            href="https://cimpress-support.atlassian.net/servicedesk/customer/portal/6"
            target="_blank"
            rel="noopener noreferrer">
            Platform Support.
          </a>
        </Modal>
      );
    }

    // TODO: fix test environment so that document is not undefined
    // @cimpress/react-components/lib/Modal blows up on any reference to document.body
    return (
      <div style={showLink ? { marginTop: '13px' } : {}}>
        {showLink ? <HeaderLink onClick={this.showModal} selectedTenant={label} /> : null}
        <SelectorModal
          authToken={authToken}
          show={showModal || show}
          selectedMerchant={selectedMerchant}
          selectedFulfiller={selectedFulfiller}
          mustSelectTenant={mustSelectTenant}
          onClose={this.closeModal}
          onSave={this.save}
          canSave={!!(tenantId && tenantName && tenantType)}
          loading={fetchingFulfillers || fetchingMerchants}
          onChange={this.onChange}
          merchants={allowedMerchants}
          fulfillers={allowedFulfillers}
          resourceType={resourceType || tenantType || ''}
          title={modalTitle}
          helpText={modalHelpText}
        />
      </div>
    );
  }
}

export default TenantSelector;
